import React, { useState } from "react"
import { ValidationError } from "@formspree/react"
import styled from "styled-components"

interface TextAreaProps {
  className?: string
  required?: boolean
  title: string
  name: string
  placeholder: string
  formSpreeState: any
  value?: string
  onChange?: (e: any) => void
}

interface TextAreaWithCounterProps extends TextAreaProps {
  className?: string
  maxLength?: number
  defaultValue?: string
  height?: string
}
const TextArea = ({
  className,
  required,
  title,
  name,
  placeholder,
  formSpreeState,
  ...props
}: TextAreaProps) => {
  return (
    <div className={className}>
      <h4 className="text-24px mb-4 font-normal">{title}</h4>
      <textarea
        name={name}
        rows={6}
        required={required}
        placeholder={placeholder}
        className="block resize-none placeholder-gray-1 border border-gray-10 rounded-md shadow-sm bg-white text-gray-1 text-15px py-10px px-15px mb-8 w-full"
        {...props}
      />
      <ValidationError
        prefix={title}
        field={name}
        errors={formSpreeState.errors}
      />
    </div>
  )
}

export const TextAreaWithCounter = styled(
  ({
    className,
    maxLength = 500,
    defaultValue = "",
    ...props
  }: TextAreaWithCounterProps) => {
    const [state, setState] = useState({
      value: defaultValue,
      length: defaultValue.length,
    })

    function handleChange(e: any): void {
      const _value = e.target.value

      if (_value.length > maxLength) {
        return
      }

      state.value = _value
      state.length = _value.length
      setState({ ...state })
    }

    return (
      <div className={className}>
        <TextArea value={state.value} onChange={handleChange} {...props} />
        <span className="counter">
          {state.length}/{maxLength}
        </span>
      </div>
    )
  }
)`
  position: relative;
  textarea {
    min-height: ${props => props.height || "8rem"};
    padding-bottom: 30px !important;
    line-height: 20px;
  }
  .counter {
    position: absolute;
    right: 15px;
    bottom: 10px;
    background: #ffffff80;
    font-family: "LatoRegular";
    color: #3d4353;
    font-size: 15px;
    line-height: 18px;
  }
`

export default TextArea
